<template>
  <div class="card">
    <div class="logo">
      <router-link to="/">
        <img
          :src="require('@/assets/images/defaults/logo.png')"
          alt="Logo"
          style="width: 200px; height: 60px"
        />
      </router-link>
    </div>
    <div class="card__heading">
      <slot name="title"></slot>
    </div>

    <div class="card__caption">
      <slot name="subtitle"></slot>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "CardWithLogo",
  };
</script>

<style lang="scss" scoped>
  .card {
    position: relative;
    width: 320px;
    padding: 2rem;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 5px 10px lightgray;

    &__heading {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 1rem;
      letter-spacing: 1px;
    }

    &__caption {
      color: gray;
      margin-bottom: 2rem;
    }

    .logo {
      position: absolute;
      top: 0;
      left: 50%;

      transform: translate(-50%, -50%);
      padding: 0 1rem;
      background: white;

      img {
        height: 100px;
      }
    }
  }
</style>
